<template>
    <div>
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-auto" outlined>
                    <v-list-item three-line>
                        <v-list-item-content>
                            <div class="text-overline mb-4">{{ getCurrentFirma.id }}</div>
                            <v-list-item-title class="text-h5 mb-1">
                                <v-select v-if="isFirmenArray" item-text="name" :items="getFirmenList"
                                    :value="getCurrentFirma" v-on:change="setCurrentFirma" return-object></v-select>
                                <div v-else>{{ getCurrentFirma.name }}</div>
                            </v-list-item-title>
                            <v-list-item-subtitle>
                                {{ getCurrentFirma.strasse }} {{ getCurrentFirma.hausnummer
                                }}{{ getCurrentFirma.hausnummer_zusatz }}<br />
                                {{ (getCurrentFirma.plz + "").padStart(5, "0") }} {{ getCurrentFirma.ort }}
                            </v-list-item-subtitle>
                        </v-list-item-content>
                    </v-list-item>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">
                <v-card class="mx-auto" outlined>
                    <v-simple-table dense>
                        <template v-slot:default>
                            <tbody>
                                <tr>
                                    <td>Vertragsnummer</td>
                                    <td>
                                        <!--{{  aktuellerVertragsname }}-->
                                        <v-select v-model="aktuellerVertragsname" :items="aktuelleVertragsnamen"
                                            item-text="Vertrag" single-line></v-select>
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertragsart</td>
                                    <td v-if="aktuellerVertrag">
                                        {{ aktuellerVertrag.vertragsart }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Energieart</td>
                                    <td v-if="aktuellerVertrag">
                                        {{ aktuellerVertrag.commodity }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertragsbeginn</td>
                                    <td>
                                        {{ aktuellerVertrag.vertragsbeginn }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>Vertragsende</td>
                                    <td>
                                        {{ aktuellerVertrag.vertragsende }}
                                    </td>
                                </tr>
                                <!--<tr>
                                    <td>Anzahl Lieferstellen</td>
                                    <td>
                                        {{ aktuellerVertrag.anzahlMarktlokationen }}
                                        (<v-btn plain small @click="showLieferstellen = !showLieferstellen">
                                            <span v-if="showLieferstellen == false">Anzeigen</span>
                                            <span v-if="showLieferstellen == true">Verbergen</span>
                                        </v-btn>)

                                    </td>
                                </tr>-->
                            </tbody>
                        </template>
                    </v-simple-table>
                </v-card>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12" md="6">
                <v-card class="mx-auto" outlined>
                    <v-card-title>Tranchen: </v-card-title>
                    <v-row>
                        <v-col>
                            <v-simple-table dense>
                                <template v-slot:default>
                                    <tbody>
                                        <tr v-if="$store.state.contract.aktuelleTranchenzeitraeume.length > 1">
                                            <td>Zeitraum</td>
                                            <td>
                                                Zeitraum
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Laufzeit von</td>
                                            <td>
                                                {{ aktuellerVertrag.vertragsbeginn }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Laufzeit bis</td>
                                            <td>
                                                {{ aktuellerVertrag.vertragsende }}
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                aktueller Preis Einzeltranche pro MWh ({{ priceDate }})
                                            </td>
                                            <td>
                                                Base x
                                                Baseprice
                                                €/MWh +
                                                Strukturkonstante
                                                €/MWh =
                                                Endpreis
                                                €/MWh
                                            </td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                            <v-simple-table fixed-header dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="text-left grey lighten-2">Tranche</th>
                                            <th class="text-left grey lighten-2">Energievolumen (kWh)<br><span
                                                    class="font-italic">gerundet auf kWh</span></th>
                                            <th class="text-left grey lighten-2">Kosten der Tranche (€)</th>
                                            <th class="text-left grey lighten-2">Preis (€/MWh)</th>
                                            <th class="text-left grey lighten-2">Bestellstatus</th>
                                        </tr>

                                    </thead>
                                    <tbody>
                                        <tr v-for="tranchennummer in sortedTranchen" :key="tranchennummer">


                                            <td>
                                                {{ tranchennummer }}
                                            </td>
                                            <td>
                                                {{

                                                    computedTranches[tranchennummer].tranchenmenge.toLocaleString("de-DE", {
                                                        maximumFractionDigits: 0,
                                                    })

                                                }}
                                            </td>
                                            <td>

                                                {{
                                                    computedTranches[tranchennummer].tranchenerloes.toLocaleString("de-DE",
                                                        {
                                                            maximumFractionDigits: 2,
                                                        })
                                                }}

                                            </td>
                                            <td>


                                                {{
                                                    (computedTranches[tranchennummer].durchschnittspreis *
                                                        10).toLocaleString("de-DE", {
                                                            maximumFractionDigits: 2,
                                                        })
                                                }}
                                            </td>
                                            <td>
                                                {{ computedTranches[tranchennummer].status }} ( {{ convertDatetime(computedTranches[tranchennummer].bestellzeitpunkt) }})
                                                

                                            </td>
                                        </tr>
                                    </tbody>
                                    <tfoot>
                                        <tr>
                                            <th class="text-left grey lighten-4">Summe</th>
                                            <th class="text-left grey lighten-4 ">{{
                                                summedUpTranches["tranchenmenge"]
                                            }}
                                                kWh</th>
                                            <th class="text-left grey lighten-4">{{
                                                summedUpTranches["tranchenerloes"]
                                            }}
                                                € Kosten bisher
                                            </th>
                                            <th class="text-left grey lighten-4">
                                                {{ summedUpTranches["durchschnittspreis"] }} mittlerer Preis (€/MWh)
                                            </th>
                                            <th class="text-left grey lighten-4"></th>
                                        </tr>
                                    </tfoot>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
            <v-col cols="12" md="6">

            </v-col>
        </v-row>

        <v-divider></v-divider>
    </div>
</template>

<script>

import axios from "axios"
import { parse } from "date-fns";
import { mapState } from "vuex"

export default {
    components: {

    },
    data() {
        return {
            isLoading: false,
            loading: {
                isFullPage: true,
                canCancel: false,
                color: "#78AEF5"
            },
            readonly: true,
            priceDate: "",
            componentKey: 0,
            tableKey: 0,
            stopplossKey: 0,
            bestellTranche: "",
            currentFirma: {},
            dialog: {
                bestellungAusfuehren: 0
            },
            showLieferstellen: false,
        }
    },
    methods: {
        germanNumberStringToNumber(string) {
            let output
            if (typeof (string) == "string") {
                // 4 Möglichkeiten
                // a) Es ist nur ein . im String => ist Number: parseFloat ausreichend
                // b) Es ist nur ein , im String => deutsche Zahl: replace(",", ".") + parseFloat
                // c) Es ist Beides vorhanden. "." erscheint vor "," => deutsche Zahl: replace(".", "") + replace(",", ".") + parseFloat
                // d) Es ist Beides vorhanden. "," erscheint vor "." => amerikanische Zahl: parseFloat ausreichend
                const posPunkt = string.indexOf(".")
                const posKomma = string.indexOf(",")
                if (posPunkt > -1 && posKomma == -1) {
                    // Möglichkeit a)
                } else if (posKomma > -1 && posPunkt == -1) {
                    // Möglichkeit b)
                    string = string.replace(",", ".")
                } else if (posPunkt > -1 && posKomma > -1) {
                    if (posPunkt < posKomma) {
                        // Möglichkeit c
                        string = string.replaceAll(".", "")
                        string = string.replace(",", ".")
                    } else {
                        // Möglichkeit d
                        string = string.replaceAll(",", "")
                    }
                }
                output = parseFloat(string)
            }
            return output;
        },

        async getPrice(product, debug = false) {
            var data = new FormData()
            data.append("apikey", process.env.VUE_APP_FIREBASE_APIKEY)
            data.append("product", product)
            if (debug) {
                data.append("debug", "true")
            }

            var config = {
                method: "post",
                url: process.env.VUE_APP_FIREBASE_HOSTING + "/queryPrice",
                data: data
            }

            let response = await axios(config)
            console.log(response.data)
            return response.data
        },
        convertDatetime(timestring) {
            let date = parse(timestring, "yyyy-MM-dd'T'HH:mm:ss.SSSX", new Date());
            return date.toLocaleString("de-DE")
        },
        getDateFromTS(ts) {
            if (typeof (ts) == "object") {
                ts = ts.seconds * 1000
            }
            const timestamp = parseInt(ts)
            const pricedateobj = new Date(timestamp)
            return pricedateobj.toLocaleString("de-DE")
        },
        setCurrentFirma(firma) {
            console.log("setCurrentFirma", firma)
            this.$store.dispatch("switchFirma", firma)
            console.log("aktuellerVertrag",this.aktuellerVertrag);
        },
    },
    computed: {
        ...mapState("contractSoptim", ["aktuellerVertrag", "aktuelleVertragsnamen", "aktuellerVertragsname"]),

        sortedTranchen() {
            if (this.aktuellerVertrag.soptimTranchen) {
                const filteredTranches = this.aktuellerVertrag.soptimTranchen.tranchendata.filter(
                    tranche => tranche.status === "BESTELLT"
                );
                const tranchenlist = filteredTranches.map(a => a.bezeichnung)
                tranchenlist.sort(function (a, b) {
                    const aNumber = a.match(/\d+$/)[0];
                    const bNumber = b.match(/\d+$/)[0];
                    return aNumber - bNumber;
                })
                return tranchenlist
            } else {
                return {}
            }
        },
        computedTranches() {
            if (this.aktuellerVertrag.soptimTranchen) {
                return this.aktuellerVertrag.soptimTranchen.tranchendata.reduce((acc, tranche) => {
                    if (tranche.status === "BESTELLT") {
                        acc[tranche.bezeichnung] = tranche;
                    }
                    return acc;
                }, {});
            } else {
                return {}
            }
        },
        summedUpTranches() {
            let summedUp = {
                count: 0,
                tranchenmenge: 0,
                tranchenerloes: 0,
                gewichteteSummePreis: 0 // Zwischenspeicher für die gewichtete Summe des Preises
            };

            // Überprüfen, ob `sortedTranchen` und `computedTranches` gültig sind
            if (!Array.isArray(this.sortedTranchen) || this.sortedTranchen.length === 0) {
                return summedUp;
            }

            for (let tranchennummer of this.sortedTranchen) {
                const tranche = this.computedTranches[tranchennummer];
                if (tranche) {
                    summedUp.count++;
                    summedUp.tranchenmenge += tranche.tranchenmenge || 0;
                    summedUp.tranchenerloes += tranche.tranchenerloes || 0;

                    // Gewichtete Summe des Preises (durchschnittspreis * tranchenmenge)
                    summedUp.gewichteteSummePreis += (tranche.durchschnittspreis || 0) * (tranche.tranchenmenge || 0);
                }
            }

            // Berechnungen nur durchführen, wenn `tranchenmenge` > 0 ist
            if (summedUp.tranchenmenge > 0) {
                return {
                    tranchenmenge: (summedUp.tranchenmenge).toLocaleString("de-DE", { maximumFractionDigits: 2 }),
                    tranchenerloes: (summedUp.tranchenerloes).toLocaleString("de-DE", { maximumFractionDigits: 2 }),
                    durchschnittspreis: (summedUp.gewichteteSummePreis / summedUp.tranchenmenge * 10).toLocaleString("de-DE", { maximumFractionDigits: 2 }), // gewichteter Durchschnittspreis
                    //durchschnittspreis: (summedUp.gewichteteSummePreis / summedUp.tranchenmenge * 10), // gewichteter Durchschnittspreis
                };
            } else {
                return summedUp; // Falls keine gültigen Daten vorliegen
            }
        },
        isFirmenArray() {
            if (!this.$store.state.auth.firmen) { return }
            return this.$store.state.auth.firmen.length > 1 ? true : false
        },
        getCurrentFirma() {
            return this.$store.state.auth.currentFirma
        },
        getFirmenList() {
            return this.$store.state.auth.firmen
        },
    },
    watch: {
        async getCurrentFirma(newval) {
            this.$store.dispatch("contractSoptim/holeVertragsdaten", { firma_id: newval.id })
        },

    },
    async mounted() {
        // SignIn Check
        if (!this.$store.state.auth.isSignedIn) {
            this.$router.push("/login")
            return
        }
        this.$store
            .dispatch("contractSoptim/holeStammdatenSoptim", { firmen: this.$store.state.auth.firmen })
            .then(async () => {
                const cur = this.getCurrentFirma
                await this.$store.dispatch("contractSoptim/holeVertragsdaten", { firma_id: cur.id })
                console.log("aktuellerVertrag",this.aktuellerVertrag);
                console.log("store",this.$store.state)
            })
    },
    getFirmenList() {
        if (!this.$store.state.auth.currentFirma) {
            this.setCurrentFirma(this.$store.state.auth.firmen[0])
        }
        this.currentFirma = this.$store.state.auth.currentFirma
    }
}
</script>

<style>
.v-data-table table tfoot th {
    position: sticky;
    bottom: 0;

}
</style>